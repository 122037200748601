import request from '@/auth/jwt/request'

/**
 * @description 项目管理 -- 项目管理列表
 * @param {Object} param params {Object} 传值参数
 */
export const videoListApi = params => { return request('project-video/list', 'get', params) }

/**
 * @description 项目管理 -- 增加修改项目
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const videoSaveApi = data => { return request('project-video/save', 'post', {}, data) }

/**
 * @description 话术管理 -- 话术分类下拉
 * @param {Object} param params {Object} 传值参数
 */
export const videoProjetSelectApi = () => { return request('project/select', 'get', null) }

/**
 * @description 项目管理 -- 项目状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const videoStatusApi = params => { return request('project-video/status', 'get', params) }

/**
 * @description 项目管理 -- 项目详情
 * @param {Object} param params {Object} 传值参数
 */
export const videoInfoApi = params => { return request('project-video/info', 'get', params) }
/**
 * @description 项目管理 -- 删除项目
 * { id = x }
 */
export const videoDeleteApi = data => { return request('project-video/delete', 'delete', data) }